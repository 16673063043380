<template>
  <StandardDialog
    v-model="localValue"
    persistent
    title="Dettaglio Transazione"
    dialog-height="calc(90vh-1)"
    dialog-width="80vw"
  >
    <template v-slot:header-actions="{ on, attrs }">
      <v-btn
        color="error" 
        text
        link
        :loading="loadingDelete"
        @click="deleteTransaction"
        v-if="currentUser.system"
      >
        <v-icon class="mr-2">mdi-delete</v-icon> Elimina
      </v-btn>
      <v-btn 
        text 
        link 
        @click="reprintFiscalReceipt"
      >
        <v-icon class="mr-2">mdi-printer</v-icon> Stampa Copia S.F.
      </v-btn>
      <v-btn 
        text 
        link 
        @click="printSummary"
      >
        <v-icon class="mr-2">mdi-printer</v-icon> Stampa Scontrino
      </v-btn>
    </template>
    <div v-if="!!rowSelected">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              :value="rowSelected.customer"
              filled
              dense
              hide-details="auto"
              label="Cliente"
              diasbled
            ></v-text-field>
          </v-col>
          <v-col>
            <DatePicker
              v-model="rowSelected.date"
              readonly
              label="Data"
            ></DatePicker>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-text-field
              v-model="cashPaid"
              :disabled="Number(rowSelected.ghostPaid) > 0"
              filled
              dense
              min="0"
              type="number"
              suffix="€"
              hide-details="auto"
              label="Contanti"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="checkPaid"
              :disabled="Number(rowSelected.ghostPaid) > 0"
              filled
              dense
              min="0"
              type="number"
              suffix="€"
              hide-details="auto"
              label="Assegno"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="cardPaid"
              :disabled="Number(rowSelected.ghostPaid) > 0"
              filled
              dense
              min="0"
              type="number"
              suffix="€"
              hide-details="auto"
              label="Carta"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="otherPaid"
              :disabled="Number(rowSelected.ghostPaid) > 0"
              filled
              dense
              min="0"
              type="number"
              suffix="€"
              hide-details="auto"
              label="Altri Metodi"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="customerCardPaid"
              readonly
              filled
              dense
              min="0"
              type="number"
              suffix="€"
              hide-details="auto"
              label="Tessera Cliente"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              :value="Number(rowSelected.total).toFixed(2)"
              filled
              dense
              readonly
              type="number"
              suffix="€"
              hide-details="auto"
              label="Totale"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :value="Number(rowSelected.totalForItems).toFixed(2)"
              filled
              dense
              readonly
              type="number"
              suffix="€"
              hide-details="auto"
              label="Totale Prodotti"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :value="Number(rowSelected.totalForServices).toFixed(2)"
              filled
              dense
              type="number"
              suffix="€"
              readonly
              hide-details="auto"
              label="Totale Servizi"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :value="Number(rowSelected.discounts).toFixed(2)"
              filled
              dense
              type="number"
              suffix="€"
              readonly
              hide-details="auto"
              label="Totale Sconti"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-alert v-if="!!rowSelected.items" dense>
        <div class="mt-2 d-flex">
          <span class="font-italic font-weight-light">Lista Prodotti</span>
          <v-btn
            icon
            @click="()=>{ dialogBarcode = true }"
            class="ml-auto"
          >
            <v-icon>mdi-shield-account-variant</v-icon>
          </v-btn>
        </div>
      </v-alert>
        <TypeDataTable
          :show-select="false"
          item-key="id"
          fixed-header
          :show-actions="!!canEditOperator"
          :deletable="false"
          :headers="headersItems"
          :items="rowSelected.items"
          @edit="handleEditItemOperator"
          v-if="!!rowSelected.items"
        >
          <template v-slot:custom-operators="{ item }">
            <ul>
              <li v-for="value in item.operators" :key="value.id">
                {{ value.firstname }} {{ value.lastname }}
              </li>
            </ul>
          </template>
        </TypeDataTable>
        <v-alert dense>
          <div v-if="!!rowSelected.services" class="font-italic font-weight-light">Lista Servizi</div>
        </v-alert>
        <TypeDataTable
          :show-select="false"
          item-key="id"
          fixed-header
          :show-actions="!!canEditOperator"
          :deletable="false"
          :headers="headersServices"
          :items="rowSelected.services"
          @edit="handleEditServiceOperator"
          v-if="!!rowSelected.services"
        >
          <template v-slot:custom-operators="{ item }">
            <ul>
              <li v-for="value in item.operators" :key="value.id">
                {{ value.firstname }} {{ value.lastname }}
              </li>
            </ul>
          </template>
        </TypeDataTable>
    </div>

    <template v-slot:footer>
      <v-spacer></v-spacer>
      <v-btn text color="error" @click="close">Chiudi</v-btn>
      <v-btn text color="default" @click="save">Salva</v-btn>
    </template>

    <StandardDialog
      v-model="editServiceOperatorDialog"
      :dialog-height="null"
      dialog-max-width="500px"
      title="Modifica Operatore Servizio"
    >
      <OperatorsAutocomplete
        v-model="editOperator"
        :multiple="false"
        class="ml-7 mr-1"
        return-object
      ></OperatorsAutocomplete> 

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="()=>editServiceOperatorDialog=false">Chiudi</v-btn>
        <v-btn text color="default" @click="editServiceOperator" :loading="editServiceOperatorLoading">Salva</v-btn>
      </template>
    </StandardDialog>
    <StandardDialog
      v-model="editItemOperatorDialog"
      :dialog-height="null"
      dialog-max-width="500px"
      title="Modifica Operatore Prodotto"
    >
      <OperatorsAutocomplete
        v-model="editOperator"
        :multiple="false"
        class="ml-7 mr-1"
        return-object
      ></OperatorsAutocomplete> 

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="()=>editItemOperatorDialog=false">Chiudi</v-btn>
        <v-btn text color="default" @click="editItemOperator" :loading="editItemOperatorLoading">Salva</v-btn>
      </template>
    </StandardDialog>

    <StandardDialog
      v-model="dialogBarcode"
      title="Area Riservata Sviluppatori" 
      :dialog-height="null"
      dialog-max-width="500px"
    >
      <ManualBarcodeInput
        v-model="editOperatorBarcode"
        scan-your-barcode-message="Immettere Codice" 
        @confirm="activeEditOperator"
      >
      </ManualBarcodeInput>
    </StandardDialog>

  </StandardDialog>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import DatePicker from "@/components/common/DatePickerCommon.vue";
import paymentTransactionsService from '@/services/paymentTransactions/paymentTransactions.service.js'
import OperatorsAutocomplete from "@/components/common/OperatorsAutocomplete.vue";
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import Printer from '@/services/devices/rtPrinter';
import operatorService from '@/services/operators/operators.service.js'

export default {
  name: "PaymentTransactionDetailDialog",
  components: {
    DatePicker,
    StandardDialog,
    TypeDataTable,
    OperatorsAutocomplete,
    ManualBarcodeInput,
  },
  data: function () {
    return {
      localValue: false,
      cashPaid: 0,
      cardPaid: 0,
      customerCardPaid: 0,
      otherPaid: 0,
      checkPaid: 0,
      headersItems: [
        { text: "Descrizione", value: "description" },
        { text: "Quantità", value: "quantity" },
        { text: "Prezzo Di Vendita", value: "priceSelling", type: "price" },
        { text: "Operatori", value: "operators", type: "custom" },
      ],
      headersServices: [
        { text: "Descrizione", value: "name" },
        { text: "Quantità", value: "quantity" },
        { text: "Prezzo Di Vendita", value: "price", type: "price" },
        { text: "Operatori", value: "operators", type: "custom" },
      ],
      serviceToEdit: undefined,
      editServiceOperatorDialog: false,
      itemToEdit: undefined,
      editItemOperatorDialog: false,
      editOperator: undefined,
      dialogBarcode: false,
      editOperatorBarcode: undefined,
      canEditOperator: false,
      editServiceOperatorLoading: false,
      editItemOperatorLoading: false,
      loadingDelete: false,
      currentUser: {}
    };
  },
  props: {
    value: {
      type: Boolean,
    },
    rowSelected: {
      type: Object,
      default: undefined,
    },
  },
  mounted() {
    if (!!this.rowSelected) {
      this.cashPaid = !!this.rowSelected.cashPaid ? Number(Number(this.rowSelected.cashPaid).toFixed(2)) : 0 
      this.otherPaid = !!this.rowSelected.otherPaid ? Number(Number(this.rowSelected.otherPaid).toFixed(2)) : 0 
      this.cardPaid = !!this.rowSelected.cardPaid ? Number(Number(this.rowSelected.cardPaid).toFixed(2)) : 0 
      this.checkPaid = !!this.rowSelected.checkPaid ? Number(Number(this.rowSelected.checkPaid).toFixed(2)) : 0 
      this.customerCardPaid = !!this.rowSelected.customerCardPaid ? Number(Number(this.rowSelected.customerCardPaid).toFixed(2)) : 0 
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      if (Number(Number(this.rowSelected.total).toFixed(2)) != (Number(Number(this.checkPaid).toFixed(2)) + Number(Number(this.otherPaid).toFixed(2)) + Number(Number(this.cashPaid).toFixed(2)) + Number(Number(this.cardPaid).toFixed(2)) + Number(Number(this.customerCardPaid).toFixed(2))) 
        || Number(Number(this.checkPaid).toFixed(2)) < 0 || Number(Number(this.otherPaid).toFixed(2)) < 0 || Number(Number(this.cardPaid).toFixed(2)) < 0 || Number(Number(this.cashPaid).toFixed(2)) < 0)
        this.$delegates.snackbar('Attenzione! I valori inseriti non sono corretti')
      else {
        let objectToModify = {
          "id": this.rowSelected.id,
          "cashPaid": Number(Number(this.cashPaid).toFixed(2)),
          "otherPaid": Number(Number(this.otherPaid).toFixed(2)),
          "cardPaid": Number(Number(this.cardPaid).toFixed(2)),
          "checkPaid": Number(Number(this.checkPaid).toFixed(2)),
        }
        paymentTransactionsService.update(objectToModify).then(() => {
          this.$emit("close");
          this.$emit("reload-payments");
        })
      }
    },
    handleEditServiceOperator(val){
      this.serviceToEdit = val
      this.editServiceOperatorDialog = true
      this.editOperator = { id: val.operators[0].id, firstname: val.operators[0].firstname, lastname: val.operators[0].lastname }
    },
    activeEditOperator(){
      operatorService.canPerformOperation(this.editOperatorBarcode, "Payments").then((result) => {
        if (result) {
          this.canEditOperator = !this.canEditOperator    
        } else {
          alert("Non hai i permessi per eseguire questa azione!")
        }
      }).catch(() => {
        alert("Badge non riconosciuto")
      })
      this.editOperatorBarcode = undefined
      this.dialogBarcode = false
    },
    editServiceOperator(){
      this.editServiceOperatorLoading = true
      let items = this.rowSelected.info.items
      let serviceToEdit = items.find(item => item.serviceId == this.serviceToEdit.serviceId)
      items = items.filter((item) => item.serviceId != this.serviceToEdit.serviceId)
      items.push({
        ...serviceToEdit,
        operators: [{
          firstname: this.editOperator.firstname,
          lastname: this.editOperator.lastname,
          id: this.editOperator.id,
          quantity: serviceToEdit.quantity
        }]
      })

      let serviceOperatorObject = {
        operatorId: this.editOperator.id,
        quantity: serviceToEdit.quantity,
        serviceId: serviceToEdit.serviceId,
      }

      this.rowSelected.info.items = items

      let objectToModify = {
        "id": this.rowSelected.id,
        "info": this.rowSelected.info,
      }
      paymentTransactionsService.updateServiceOperator({transaction: objectToModify, service: serviceOperatorObject}).then(() => {
        this.$emit("close");
        this.$emit("reload-payments");
        this.editServiceOperatorLoading = false
      })
    },
    handleEditItemOperator(val){
      this.itemToEdit = val
      this.editItemOperatorDialog = true
      this.editOperator = { id: val.operators[0].id, firstname: val.operators[0].firstname, lastname: val.operators[0].lastname }
    },
    editItemOperator(){
      this.editItemOperatorLoading = true
      let items = this.rowSelected.info.items
      let itemToEdit = items.find(item => item.itemId == this.itemToEdit.itemId)
      items = items.filter((item) => item.itemId != this.itemToEdit.itemId)
      items.push({
        ...itemToEdit,
        operators: [{
          firstname: this.editOperator.firstname,
          lastname: this.editOperator.lastname,
          id: this.editOperator.id,
          quantity: itemToEdit.quantity
        }]
      })

      let itemOperatorObject = {
        operatorId: this.editOperator.id,
        quantity: itemToEdit.quantity,
        itemId: itemToEdit.itemId,
      }

      this.rowSelected.info.items = items

      let objectToModify = {
        "id": this.rowSelected.id,
        "info": this.rowSelected.info,
      }
      paymentTransactionsService.updateItemOperator({transaction: objectToModify, item: itemOperatorObject}).then(() => {
        this.$emit("close");
        this.$emit("reload-payments");
        this.editItemOperatorLoading = false
      })
    },
    async printSummary(){
      let mappedServices = this.rowSelected.services.map(service => {
        service.price = service.realPrice
        return service
      })
      let mappedItems = this.rowSelected.items.map(item => {
        item.price = item.realPrice
        return item
      })

      await Printer.printSummary({
        amount: this.rowSelected.total,
        items: [...mappedServices, ...mappedItems],
        discounts: undefined,
      }, false, "paymentTransactionDetail invoice")
    },
    async reprintFiscalReceipt() {
      let params = {
        date: new Date(this.rowSelected.date),
        number: this.rowSelected.info.documentNumber,
      }

      await Printer.reprintFiscalReceipt(params)
    },
    async deleteTransaction() {
      this.loadingDelete = true
      paymentTransactionsService.delete(this.rowSelected).then(() => {
        this.$emit("close");
        this.$emit("reload-payments");
        this.loadingDelete = false
      })
    },
  },
  currentUser: {
    bind: 'currentUser'
  },
  computed: {},
  watch: {
    value(newVal) {
      this.localValue = newVal;
    },
    rowSelected(newVal) {
      if (!!newVal) {
        this.cashPaid = !!newVal.cashPaid ? Number(Number(newVal.cashPaid).toFixed(2)) : 0 
        this.otherPaid = !!newVal.otherPaid ? Number(Number(newVal.otherPaid).toFixed(2)) : 0 
        this.cardPaid = !!newVal.cardPaid ? Number(Number(newVal.cardPaid).toFixed(2)) : 0 
        this.checkPaid = !!newVal.checkPaid ? Number(Number(newVal.checkPaid).toFixed(2)) : 0 
        this.customerCardPaid = !!newVal.customerCardPaid ? Number(Number(newVal.customerCardPaid).toFixed(2)) : 0 
      }
    }
  },
};
</script>